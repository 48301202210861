import { lang } from "../../data/language"
import { ButtonBuild, ButtonAssign } from "../ui/Buttons"
import { PopUpAssign } from "../../modal/ModalReg/PopUpMessage"
import { useState, useContext, useEffect } from 'react';
import MainContext from '../../context/Main';
import { UpdateBuilding } from '../../api/Api';
import { PopUpMessage } from "../../modal/ModalReg/PopUpMessage";
import { BuildProgress } from "../ui/BuildProgress";



export default function BuildFieldInfo(param) {



    let buildType = param.buildsFieldPage
    if (typeof buildType === 'object' && buildType !== null) {
        buildType = buildType.build_name
    }

    /// Exception for uniq_build:
    let b_type = buildType
    const uniq_list = ["ore", "sulfur", "flax"]
    if (uniq_list.includes(buildType)) {
        b_type = "uniq_build"
    }



    const [stateAssign, setStateAssign] = useState(false)

    const AppData = useContext(MainContext);

    const [buildButton, setBuildButton] = useState(0)
    const [buildError, setBuildError] = useState(false)
    const [buildResourcesNotEnough, setBuildResourcesNotEnough] = useState(false)
    const [buildBuildInProgress, setBuildInProgress] = useState(false)



    const [inProgress, setInProgress] = useState({
        "started": AppData.island.buildings[b_type].build_started,
        "completed": AppData.island.buildings[b_type].build_completed
    })


    useEffect(() => {

        // if to prevent from initial loading
        if (buildButton > 0) {

            UpdateBuilding(buildType, AppData.island.name)
                .then(response => {
                    // const status = response['status']
                    // const data = response['data']


                    // Not enough resources for building:
                    if (response['status'] === 402) {
                        setBuildResourcesNotEnough(true)
                    }
                    // Build already in progress:
                    else if (response['status'] === 405) {
                        setBuildInProgress(true)
                    }
                    else if (response['status'] !== 200) {
                        console.error('Smth went wrong:', response['status']);
                        setBuildError(true)

                    }
                    else {
                        // Trigger reload island's data from backend 
                        AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)
                    }


                })
                .catch(error => {
                    console.error('Error:', error);
                    // Handle the error, e.g., show an error message
                });
        }
    }, [buildButton]);

    // Show progress bar after build started
    useEffect(() => {
        setTimeout(() => {
            setInProgress({
                "started": AppData.island.buildings[b_type].build_started,
                "completed": AppData.island.buildings[b_type].build_completed
            })
        }, 200);

    }, [AppData.island.buildings[b_type]]);

    return (
        <div className="build_field_info">

            {
                PopUpAssign({
                    in_open: stateAssign,
                    in_onClick: () => setStateAssign(false),
                    buildType: buildType
                })
            }



            <img className='build_field_image' alt="" src={'/img/buildings/' + AppData.account.nation + '/' + buildType + '.webp'}></img>
            <h4 className='build_field_text'>{lang.buildings[buildType].name}</h4>
            <p className='build_field_text'>{lang.buildings[buildType].description}</p>

            <p className='build_field_text'>{lang.buildings[buildType].count}  <b>{AppData.island.buildings[b_type].amount}</b></p>


            {buildType === 'homes' &&
                <>
                    <p className='build_field_text'>{lang.buildings[buildType].current_pop} <b> {Math.round(AppData.island.resources.settlers)} </b></p>
                    <p className='build_field_text'>{lang.buildings[buildType].max_pop} <b> {AppData.island.buildings[b_type].amount * AppData.commonData.buildings.homes.settlers_income} </b></p>
                </>

            }


            {(['plantations', 'timber'].includes(buildType)) &&

                <p className='build_field_text'>
                    {lang.buildings[buildType].workers}
                    <br />
                    <b>  {AppData.island.buildings[b_type].workers + AppData.island.buildings[b_type].slaves} / {AppData.island.buildings[b_type].amount * AppData.commonData.buildings[buildType].workers}</b>
                    {/* Assign workers */}
                    <ButtonAssign
                        onClick={() => setStateAssign(true)}
                    />
                </p>
            }
            {(['flax', 'sulfur'].includes(buildType)) &&
                <p className='build_field_text'>
                    {lang.buildings[buildType].workers}
                    <br />
                    <b>  {AppData.island.buildings[b_type].workers} / {AppData.island.buildings[b_type].amount * AppData.commonData.buildings[buildType].workers}</b>
                    {/* Assign workers */}
                    <ButtonAssign
                        onClick={() => setStateAssign(true)}
                    />


                </p>
            }




            {/* Display if build not in progress */}
            {(inProgress.started === 0 && inProgress.completed === 0) ?
                <>
                    <p className='build_field_text'>{lang.buildings[buildType].cost}</p>
                    <div className="build_cost">
                        <img className='res_image' alt="" src='/ui/resources/dooblons.webp'></img> {AppData.commonData.buildings[buildType].build_cost.dooblons * AppData.commonData.factors.buildings.build_time_rate * AppData.island.buildings[b_type].amount}
                        <img className='res_image' alt="" src='/ui/resources/wood.webp'></img> {AppData.commonData.buildings[buildType].build_cost.timber * AppData.commonData.factors.buildings.build_time_rate * AppData.island.buildings[b_type].amount}
                        <img className='res_image' alt="" src='/ui/resources/metal.webp'></img> {AppData.commonData.buildings[buildType].build_cost.metal * AppData.commonData.factors.buildings.build_time_rate * AppData.island.buildings[b_type].amount}
                    </div>
                    <div className="build_cost">
                        <ButtonBuild onClick={() => setBuildButton(buildButton + 1)} />
                    </div>
                </>
                :
                // Display if build in progress
                <BuildProgress start={inProgress.started} completed={inProgress.completed} />

            }





            {/* build error handling */}
            {
                PopUpMessage({
                    in_open: buildError,
                    in_onClick: () => setBuildError(false),
                    title: lang.ui_text.alerts.build_error.title,
                    message: lang.ui_text.alerts.build_error.message
                })
            }
            {
                PopUpMessage({
                    in_open: buildResourcesNotEnough,
                    in_onClick: () => setBuildResourcesNotEnough(false),
                    title: lang.ui_text.alerts.build_not_enough_resources.title,
                    message: lang.ui_text.alerts.build_not_enough_resources.message
                })
            }
            {
                PopUpMessage({
                    in_open: buildBuildInProgress,
                    in_onClick: () => setBuildInProgress(false),
                    title: lang.ui_text.alerts.build_in_progress.title,
                    message: lang.ui_text.alerts.build_in_progress.message
                })
            }





        </div>
    )
}