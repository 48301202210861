import './Resources.css';


export default function HeadResources(param) {

  function shortFiguresForm(number) {
    if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + "b";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "m";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "k";
    } else {
      return number.toString();
    }
  }


  return (

    <section className='head_resources_section text' width="10%">
      <div className='head_resources'>
        <div className='dooblons'>
          <img className='res_image' src='/ui/resources/dooblons.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.dooblons))}</div>
        <div className='citizen'>
          <img className='res_image' alt="" src='/ui/resources/citizen.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.settlers))}</div>
        <div className='slave'>
          <img className='res_image' alt="" src='/ui/resources/slave.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.slaves))}</div>
        <div className='food'>
          <img className='res_image' alt="" src='/ui/resources/food.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.food))}</div>
        <div className='wood'>
          <img className='res_image' alt="" src='/ui/resources/wood.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.timber))}</div>

        <div className='island_name'>
          <b>[{param.islandResource.name}]</b>
        </div>

        <div className='metal'>
          <img className='res_image' alt="" src='/ui/resources/metal.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.metal))}</div>
        <div className='gunpowder'>
          <img className='res_image' alt="" src='/ui/resources/gunpowder.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.gunpowder))}</div>
        <div className='sailcloth'>
          <img className='res_image' alt="" src='/ui/resources/sailcloth.webp'></img>
          {shortFiguresForm(Math.round(param.islandResource.resources.sailcloth))}</div>

      </div>




    </section >




  );
}
