import { createPortal } from 'react-dom'
import './ModalReg.css'
import { useEffect, useState, useRef, useContext } from 'react'
import { ButtonClose, ButtonSave } from '../../components/ui/Buttons'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { lang } from "../../data/language"

import MainContext from '../../context/Main';
import { GetFreePeople, UpdateWorkers } from '../../api/Api';
import { DoneOk } from './ActionCompleted'

function ModalReg({ children, open }) {

    const dialog = useRef()

    useEffect(() => {
        if (open) {
            dialog.current.showModal()
        } else {
            dialog.current.close()
        }
    }, [open])



    return createPortal(
        <dialog ref={dialog}>
            {children}
        </dialog>,
        document.getElementById('modal-root')
    )

}




export function PopUpMessage({ in_open, in_onClick, title, message }) {

    return (
        <ModalReg open={in_open}>
            <h3>{title}</h3>
            <p>{message}</p>
            <ButtonClose onClick={in_onClick} />
        </ModalReg>
    )
}

export function PopUpAssign({ in_open, in_onClick, buildType }) {

    const AppData = useContext(MainContext);

    /// Exception for uniq_build:
    const uniq_list = ["ore", "sulfur", "flax"]

    let b_type = buildType
    if (uniq_list.includes(buildType)) {
        b_type = "uniq_build"
    }

    const [workersValue, setWorkersValue] = useState(AppData.island.buildings[b_type].workers);
    const [slavesValue, setSlavesValue] = useState(AppData.island.buildings[b_type].slaves);

    const [freePeople, setFreePeople] = useState({})
    const [saveButton, setSaveButton] = useState(0)
    const [alertError, setAlertError] = useState(false)

    const maxTotal = (AppData.island.buildings[b_type].amount * AppData.commonData.buildings[buildType].workers)

    const [maxWorkers, setMaxWorkers] = useState(null)
    const [maxSlaves, setMaxSlaves] = useState(null)



    function rangeSlideWorkers(value) {

        if (value <= freePeople.free_workers + workersValue) {

            setWorkersValue(value);
            setFreePeople({
                free_workers: freePeople.free_workers + (workersValue - value),
                free_slaves: freePeople.free_slaves
            })


            /// workers
            if (freePeople.free_workers + value < maxTotal - slavesValue) {
                setMaxWorkers(freePeople.free_workers + value)
            }
            else {
                if (["sulfur", "flax"].includes(buildType)) {
                    setMaxWorkers(maxTotal)
                }
                else {
                    setMaxWorkers(maxTotal - slavesValue)
                }
            }
            /// slaves:
            if (freePeople.free_slaves + slavesValue < maxTotal - value) {
                setMaxSlaves(freePeople.free_slaves + slavesValue)
            }
            else {
                if (["ore"].includes(buildType)) {
                    setMaxSlaves(maxTotal)
                }
                else {
                    setMaxSlaves(maxTotal - value)
                }
            }


        }

    }

    function rangeSlideSlaves(value) {

        if (value <= freePeople.free_slaves + slavesValue) {

            setSlavesValue(value);
            setFreePeople({
                free_workers: freePeople.free_workers,
                free_slaves: freePeople.free_slaves + (slavesValue - value)
            })

            /// slaves:
            if (freePeople.free_slaves + value < maxTotal - workersValue) {
                setMaxSlaves(freePeople.free_slaves + value)
            }
            else {
                if (["ore"].includes(buildType)) {
                    setMaxSlaves(maxTotal)
                }
                setMaxSlaves(maxTotal - workersValue)
            }
            /// workers:
            if (freePeople.free_workers + workersValue < maxTotal - value) {
                setMaxWorkers(freePeople.free_workers + workersValue)
            }
            else {
                if (["sulfur", "flax"].includes(buildType)) {
                    setMaxWorkers(maxTotal)
                }
                else {
                    setMaxWorkers(maxTotal - value)
                }
            }

        }
    }


    // Initialize maxSlaves and maxWorkers as it require function.
    if (maxSlaves === null && maxWorkers === null) {
        rangeSlideWorkers(workersValue)
        rangeSlideSlaves(slavesValue)
    }


    // API calls: ///////////////////
    useEffect(() => {

        GetFreePeople(AppData.island.name)
            .then(response => {
                // const status = response['status']
                const data = response['data']

                setFreePeople(data)

            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error, e.g., show an error message
            });

    }, []);


    useEffect(() => {

        // if to prevent from initial loading
        if (saveButton > 0) {

            UpdateWorkers(buildType, workersValue, slavesValue, AppData.island.name)
                .then(response => {
                    // const status = response['status']
                    const data = response['data']

                    if (response['status'] !== 200) {
                        console.error('Smth went wrong:', response['status']);
                        setAlertError(true)

                    }
                    else {
                        setVisibleDoneOk(true)
                        AppData.triggers.setIslandCall(AppData.triggers.islandCall + 1)
                    }


                })
                .catch(error => {
                    console.error('Error:', error);
                    // Handle the error, e.g., show an error message
                });
        }
    }, [saveButton]);


    // END API calls ///////////////////



    // For DoneOk element
    const [visibleDoneOk, setVisibleDoneOk] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisibleDoneOk(false);
        }, 1000); // 10000ms = 10s

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [visibleDoneOk]);
    ////////////////////


    return (
        <>
            <ModalReg open={in_open}>
                <ButtonClose onClick={in_onClick} />

                <h3>{lang.buildings[buildType].assign_title}</h3>
                <p>{lang.buildings[buildType].assign_description}</p>


                {(['plantations', 'timber', 'flax', 'sulfur'].includes(buildType)) &&
                    <span>
                        <p>{lang.buildings.ui_common.assign.max_workspaces} <b>{maxTotal}</b></p>
                        <p>
                            <b>{lang.ui_text.resources.settlers}:</b>
                        </p><p>
                            {lang.buildings.ui_common.assign.free_workers} <b>{freePeople.free_workers}</b>
                        </p><p>
                            {lang.buildings.ui_common.assign.current_workers} <b>{workersValue}</b>
                        </p>


                        {/* http://react-component.github.io/slider/?path=/story/rc-slider--slider */}
                        <Slider
                            style={{ width: 200, marginBottom: '10px' }}
                            min={0}
                            max={maxWorkers}
                            onChange={rangeSlideWorkers}
                            defaultValue={workersValue}

                            trackStyle={{ backgroundColor: '#746012', height: 10 }}
                            handleStyle={{
                                borderColor: 'blue',
                                height: 16,
                                width: 16,
                                backgroundColor: 'black',
                            }}
                            railStyle={{ backgroundColor: '#ceb34a', height: 10 }}
                        />
                    </span>
                }






                {(['plantations', 'timber', 'ore'].includes(buildType)) &&
                    <span>
                        <p>
                            <b>{lang.ui_text.resources.slaves}:</b>
                        </p><p>
                            {lang.buildings.ui_common.assign.free_slaves} <b>{freePeople.free_slaves}</b>
                        </p><p>
                            {lang.buildings.ui_common.assign.current_slaves} <b>{slavesValue}</b>
                        </p>


                        {/* http://react-component.github.io/slider/?path=/story/rc-slider--slider */}
                        <Slider
                            style={{ width: 200, marginBottom: '10px' }}
                            min={0}
                            max={maxSlaves}
                            onChange={rangeSlideSlaves}
                            defaultValue={slavesValue}

                            trackStyle={{ backgroundColor: '#746012', height: 10 }}
                            handleStyle={{
                                borderColor: 'blue',
                                height: 16,
                                width: 16,
                                backgroundColor: 'black',
                            }}
                            railStyle={{ backgroundColor: '#ceb34a', height: 10 }}
                        />
                    </span>
                }






                <ButtonSave onClick={() => setSaveButton(saveButton + 1)} />
                <DoneOk visible={visibleDoneOk} />

                {
                    PopUpMessage({
                        in_open: alertError,
                        in_onClick: () => setAlertError(false),
                        title: lang.ui_text.alerts.error_01.title,
                        message: lang.ui_text.alerts.error_01.message
                    })
                }

            </ModalReg>
        </>

    )
}