let tg = window.Telegram.WebApp; //получаем объект webapp телеграма 
tg.expand(); //расширяем на все окно  


let ghc = tg.initData
let lang = tg.initDataUnsafe.user.language_code

// let ghc = "query_id=AAESXJQuAAAAABJclC4llgT-&user=%7B%22id%22%3A7800057%2C%22first_name%22%3A%22Alexandr%22%2C%22last_name%22%3A%22Sokolov%22%2C%22username%22%3A%22sortns%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1919770656&hash=debc33177e97b96d721807e95199518dc625723554ea26b4828a21f9dc474adb"
// let lang = "en"

export async function IsNickNameExist(name) {

    let request_body = {
        "method": "IsNickNameExist",
        "params":
        {
            "name": name
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error checking nickname:', error);
        throw error;
    }
}

export async function IsIslandExist(name) {

    let request_body = {
        "method": "IsIslandExist",
        "params":
        {
            "name": name
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        }).then(response => response);

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error checking island name:', error);
        throw error;
    }
}

export async function Register(nickname, island_name, nation) {

    let request_body = {
        "method": "Register",
        "params":
        {
            "nickname": nickname,
            "language": lang,
            "nation": nation,
            "island_name": island_name,
            "capital": "true"
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
}

export async function GetAccount() {

    let request_body = {
        "method": "GetAccount",
        "params":
        {
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting account:', error);
        throw error;
    }
}

export async function UpdateTimestamp() {
    let request_body = {
        "method": "UpdateTimestamp",
        "params":
        {
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function GetIslands(capital) {
    let request_body = {
        "method": "GetIslands",
        "params":
        {
            "capital": capital,
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting islands:', error);
        throw error;
    }
}

export async function GetCommon() {
    let request_body = {
        "method": "GetCommon",
        "params":
        {
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting common data:', error);
        throw error;
    }
}

export async function GetFreePeople(island_name) {
    let request_body = {
        "method": "GetFreePeople",
        "params":
        {
            "island_name": island_name
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting common data:', error);
        throw error;
    }
}

export async function UpdateWorkers(building, workers, slaves, island_name) {
    let request_body = {
        "method": "UpdateWorkers",
        "params":
        {
            "building": building,
            "workers": workers,
            "slaves": slaves,
            "island_name": island_name
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting common data:', error);
        throw error;
    }
}

export async function UpdateBuilding(building, island_name) {
    let request_body = {
        "method": "UpdateBuilding",
        "params":
        {
            "building": building,
            "island_name": island_name
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}
export async function PushUpdateIsland(island_name) {
    let request_body = {
        "method": "PushUpdateIsland",
        "params":
        {
            "name": island_name
        },
        "qhc": ghc
    }

    try {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request_body)
        });

        return {
            "status": response.status,
            "data": await response.json()
        }

    } catch (error) {
        console.error('Error getting common data:', error);
        throw error;
    }
}
