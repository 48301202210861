
import './Menu.css';
import { lang } from '../../data/language';
import { useState, useContext } from 'react';
import BuildField from './BuildField';
import MainContext from '../../context/Main';
import MenuPanel from './menu_panel/MenuPanel';

export default function MainMenu() {

  const [currentPage, setCurrentPage] = useState('main')

  const [buildsFieldPage, setBuildsFieldPage] = useState('None')

  const AppData = useContext(MainContext);


  return (
    <div className='app-container'>

      <div id='div_main' className='scrollable-list'>


        {currentPage === 'main' &&
          <section className='text'>

            <img className='section_image' alt="" src={'/img/buildings/' + AppData.account.nation + '/main.webp'}></img>

            <div id='div_main_top_text'>

              <h4>{lang.menu.body_text.greetings} {AppData.account.nickname} <img className='button_flag_16' alt="" src={'/ui/flags/flag_' + (AppData.account.nation).toLowerCase() + '.jpg'}></img></h4>
              <p>{lang.menu.body_text.line1} "{AppData.account.nation}" {lang.menu.body_text.line1_end}</p>
              <p>{lang.menu.body_text.line2}</p>
              <p>{lang.menu.body_text.line3}</p>
            </div>
          </ section>
        }


        {currentPage === 'build' &&
          <BuildField
            buildsFieldPage={buildsFieldPage}         // Pass useState variable
            setBuildsFieldPage={setBuildsFieldPage}   // Pass useState function
          />
        }


        {(['fleet', 'defence', 'map'].includes(currentPage)) &&
          <section className='text'>

            <h4> {lang.under_development} </h4>
          </section>

        }

        {/* Отступ чтобы не накладывалось на панель внизу */}
        <br />
        <br />


        <MenuPanel
          setCurrentPage={setCurrentPage}
          setBuildsFieldPage={setBuildsFieldPage} />

      </div>
    </div>

  );
}
