let tg = window.Telegram.WebApp; //получаем объект webapp телеграма 
var language=tg.initDataUnsafe.user.language_code


// var language = 'en'



const en = {
    menu: {
        body_text: {
            greetings: 'Greetings ',
            line1: 'You are a part of ',
            line1_end: ' alliance',
            line2: "Life in your settlement goes on as usual...",
            line3: "In your settlement, everyone works for the good of the city "

        }

    },

    under_development: 'Sorry, this feature is under development',
    buildings: {
        homes: {
            name: 'Homes',
            description: 'Homes are where your settlers live. The more homes you have, the more settlers can live in your settlement. And the more taxes they pay.',
            count: 'Available homes: ',
            current_pop: 'Current population: ',
            max_pop: 'Maximum population: ',
            cost: 'Cost of construction: '
        },
        plantations: {
            name: 'Plantations',
            description: 'Plantations are where your settlement gets food and resources to survive. It is very important to have enough food to feed your people. We don`t want a famine... \nBoth our citizens and slaves can work on plantations.',
            count: 'Available plantations: ',
            workers: 'Total workers / Maximum:',
            max_workers: 'Building capacity: ',
            cost: 'Cost of construction: ',
            assign_title: 'Assign workers to plantations',
            assign_description: 'Here you can assign workers to work on plantations. As well as citizens and workers can work there'
        },
        timber: {
            name: 'Sawmills',
            description: 'Logging is in full swing here. Wood is a critical resource for the construction of literally everything, from a fishing boat to a governor`s office.',
            count: 'Amount of Sawmills: ',
            workers: 'Total workers / Maximum:',
            max_workers: 'Building capacity: ',
            cost: 'Cost of construction: ',
            assign_title: 'Assign workers to sawmills',
            assign_description: 'Here you can assign workers to work on sawmills. As well as citizens and workers can work there'
        },
        ore: {
            name: 'Mines',
            description: 'Mines are where ore is mined. Ore is a raw material for metal production. Metal is used for building weapons and tools.',
            count: 'Amount of mines: ',
            workers: 'Total workers / Maximum:',
            max_workers: 'Maximum workers: ',
            cost: 'Cost of construction: ',
            assign_title: 'Assign slaves to mines',
            assign_description: 'No self-respecting citizen would want to work in a mine. We will have to use slaves'
            
        },
        sulfur: {
            name: 'Alchemical laboratories',
            description: 'Alchemical laboratories are where sulfur is mined. Sulfur is a raw material for the production of gunpowder. A very valuable and at the same time quickly consumed resource',
            count: 'Available labs: ',
            workers: 'Total workers / Maximum:',
            max_workers: 'Maximum workers: ',
            cost: 'Cost of construction: ',
            assign_title: 'Assign citizens to labs',
            assign_description: 'Only educated personal required. Slaves are not permitted here!'
        },
        flax: {
            name: 'Weaving workshops',
            description: 'Weaving workshops are where flax is grown and processed. Flax is a raw material for the production of sailcloth. Sailcloth is used for the construction of ships.',
            count: 'Amount of workshops: ',
            workers: 'Total workers / Maximum:',
            max_workers: 'Maximum workers: ',
            cost: 'Cost of construction: ',
            assign_title: 'Assign workers to workshops',
            assign_description: 'Only educated citizens of our city can handle fabric properly and not spoil the materials. Slaves have no place in the workshop'
        },
        ui_common: {
            assign: {
                free_workers: 'Free settlers:',
                free_slaves: 'Free slaves:',
                max_workspaces: 'Maximum number of available workplaces:',
                current_workers: 'Assigned workers here:',
                current_slaves: 'Assigned settlers here:'
            },
            construction: {
                in_progress: 'Construction in progress',
                completed_in: 'Please wait... Construction should be completed in'
            }
        }



    },


    registration: {
        message_greetings: 'Welcome to Sea Cove!',
        message_ask_nickname: 'How, may I call you friend?',
        message_nickname_placeholder: 'Sir Charl',
        message_greetings_2: 'So, nice to meet you ',
        message_ask_island_name: 'How you will call your first town?',
        message_island_name_placeholder: 'Port Royal',
        message_greetings_3: 'last question before you will go to rule your settlement',
        message_ask_nation: 'Which nation your settlement will be belong to?',

        popIncorrectNickname: {
            title: 'Incorrect nickname',
            message: 'Sorry, but your name can`t be taken. Please consider another one.\nRecommendation is using only chars A-z and numbers 0-9 without any special chars.'
        },
        popIncorrectIslandName: {
            title: 'Incorrect Island name',
            message: 'Sorry, but you can`t take this name as Island name. Please consider another one.\nRecommendation is using only chars A-z and numbers 0-9 without any special chars.'
        },
        popErrorRegistration: {
            title: 'Error',
            message: 'Sorry, but we cannot register you at this moment. Please try again later. Otherwise contact to @sortns'
        },
        message_finish: 'Registration completed successfully. Please click to continue '

    },
    ui_text: {
        resources: {
            dooblons: "Dooblons",
            settlers: "Settlers",
            slaves: "Slaves",
            food: "Food",
            timber: "Wood",
            ore: "Ore",
            sulfur: "Sulfur",
            flax: "Flax",
            metal: "Metal",
            gunpowder: "Powder",
            sailcloth: "Sailcloth",
        },
        alerts: {
            error_01: {
                title: 'Error',
                message: 'Something went wrong. Please try again later, otherwise contact support in our channel'
            },
            build_error: {
                title: 'Error',
                message: 'Something went wrong. Please try again later, otherwise contact support in our channel'
            },
            build_not_enough_resources: {
                title: 'Not enough resources',
                message: 'You do not have enough resources to build this building'
            },
            build_in_progress: {
                title: 'Conflict',
                message: 'This construction is already in progress'
            }
        },
        button_continue: 'Continue',
        button_build: 'Build',
        button_save: 'Save'
    }
}


const ru = {
    menu: {
        body_text: {
            greetings: 'Приветствую ',
            line1: 'Вы являетесь частью  ',
            line1_end: ' альянса',
            line2: "Жизнь в вашем поселении проходит как обычно...",
            line3: "В вашем поселении каждый работает на его благо "
        }

    },

    under_development: 'К сожалению, данный функционал в разработке',
    buildings: {
        homes: {
            name: 'Дома',
            description: 'Дома - это место, где живут ваши граждане. Больше домов - больше населения, которое может жить в вашем поселении. И конечно больше налогов, они будут приносить.',
            count: 'Доступные дома: ',
            current_pop: 'Текущее население: ',
            max_pop: 'Максимальное население: ',
            cost: 'Стоимость постройки: '
        },
        plantations: {
            name: 'Плантации',
            description: 'Плантации - это место, где происходит рост и сбор урожая. Это очень важно иметь достаточно продовольствия для удовлетворения нужд поселенцев. Мы ведь не хотим беды... \n Как граждане так и рабы могут работать на плантациях.',
            count: 'Доступные плантации: ',
            workers: 'Всего рабочих / Максимум:',
            max_workers: 'Вместимость: ',
            cost: 'Стоимость постройки: ',
            assign_title: 'Назначьте рабочих на плантациях',
            assign_description: 'На плантациях могут работать как наши граждание так и рабы.'
        },
        timber: {
            name: 'Лесозаготовлки',
            description: 'Лесозаготовка - это место, где день и ночь слышен хруст а не шелест дерева. Дерево - важный ресурс для строительства, в том числе и кораблей.',
            count: 'Количество: ',
            workers: 'Всего рабочих / Максимум:',
            max_workers: 'Вместимость: ',
            cost: 'Стоимость постройки: ',
            assign_title: 'Назначение на лесозаготовку',
            assign_description: 'Здесь вы можете назначить рабочих на лесозаготовках. Как граждание так и рабы могут здесь работать'
        },
        ore: {
            name: 'Шахта железа',
            description: 'Глубоки шахты на нашем острове, как вены необходимые для накачки организма кровью, ну или в нашем случае металлом. Здесь добываем руду для производства металла.',
            count: 'Количество: ',
            workers: 'Всего рабочих / Максимум:',
            max_workers: 'Вместимость: ',
            cost: 'Стоимость постройки: ',
            assign_title: 'Назначение рабов в шахты',
            assign_description: 'Ни один уважающий себя гражданин не пожелает работать на руднике. Придется воспользоваться рабами'
        },
        sulfur: {
            name: 'Алхимическая лаборатория',
            description: 'Согласно древне-китайскому манускрипту ваши ученые производят в своих лабораториях очень ценный ресурс порох.',
            count: 'Доступные лаборатории: ',
            workers: 'Всего рабочих / Максимум:',
            max_workers: 'Вместимость: ',
            cost: 'Стоимость постройки: ',
            assign_title: 'Назначение в лаборатории',
            assign_description: 'Только ученные граждане подходят для работы в лаборатории'
        },
        flax: {
            name: 'Ткацкая мастерская',
            description: 'Нам повезло иметь лен на острове, чтож это позволит нам неплохо заработать на парусине. К тому же она пригодится для постройки нашего флота. В мастерской обрабатывается лен. Лен - важный ресурс для производства парусины.',
            count: 'Количество мастерских: ',
            workers: 'Всего рабочих / Максимум:',
            max_workers: 'Вместимость: ',
            cost: 'Стоимость постройки: ',
            assign_title: 'Назначение в мастерские',
            assign_description: 'Правильно обращатся с тканью и не испортить материалы могут только образованные граждане нашего города. Рабам не место в мастерской'
        },
        ui_common: {
            assign: {
                free_workers: 'Свободных поселенцев:',
                free_slaves: 'Свободных рабов:',
                max_workspaces: 'Максимальное количество мест:',
                current_workers: 'Кол-во занятых поселенцев здесь:',
                current_slaves: 'Кол-во занятых рабов здесь:'
            },
            construction: {
                in_progress: 'В процессе строительства',
                completed_in: 'Ожидаем... Оно должно быть построенно через'
            }

        }


    },

    registration: {
        message_greetings: 'Добро пожаловать в Sea Cove!',
        message_ask_nickname: 'Пожалуй начнем с Вашего имени в игре?',
        message_nickname_placeholder: 'Сэр Чарль',
        message_greetings_2: 'Ну что же, приветствую Вас ',
        message_ask_island_name: 'Как назовем первое поселение?',
        message_island_name_placeholder: 'Порт Роял',
        message_greetings_3: 'Последний вопрос перед тем как Вы приступите к управлению своим поселением.',
        message_ask_nation: 'К какой стране будет принадлежать Ваша колония?',


        popIncorrectNickname: {
            title: 'Неверное имя',
            message: 'К сожалению вы не можете использовать это имя в игре. Пожалуйста выберите другое имя.\nРекомендация использовать только буквы и цифры A-z 0-9 без специальных символов.'
        },
        popIncorrectIslandName: {
            title: 'Неверное имя острова',
            message: 'К сожалению вы не можете использовать это имя как имя острова. Пожалуйста выберите другое имя.\nРекомендация использовать только буквы и цифры A-z 0-9 без специальных символов.'
        },
        popErrorRegistration: {
            title: 'Ошибка',
            message: 'К сожалению мы не можем Вас зарегистрировать в данный момент. Пожалуйста попробуйте позже. Или обратитесь к @sortns'
        },
        message_finish: 'Регистрация завершена успешно. Нажмите чтобы продолжить '



    },
    ui_text: {
        resources: {
            dooblons: "Дублон",
            settlers: "Горожан",
            slaves: "Рабы",
            food: "Еды",
            timber: "Древесины",
            ore: "Руды",
            sulfur: "Сера",
            flax: "Лен",
            metal: "Металл",
            gunpowder: "Порох",
            sailcloth: "Парусина"
        },
        alerts: {
            error_01: {
                title: 'Ошибка',
                message: 'Что-то пошло не так. Пожалуйста попробуйте еще раз позже или напишите нам в канал, чтобы получить помощь.'
            },
            build_error: {
                title: 'Ошибка',
                message: 'Что-то пошло не так. Пожалуйста попробуйте еще раз позже или напишите нам в канал, чтобы получить помощь.'
            },
            build_not_enough_resources: {
                title: 'Недостаточно ресурсов',
                message: 'У вас недостаточно ресурсов для постройки этого здания'
            },
            build_in_progress: {
                title: 'Конфликт',
                message: 'Это здание уже уже в процессе строительства'
            }
        },
        button_continue: 'Продолжить',
        button_build: 'Построить',
        button_save: 'Сохранить'
    }
}


export const lang = (language === 'ru') ? ru : en;